"use strict";

var _interopRequireDefault = require("/home/devops/repos/1_20241012111234/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.log = log;
exports.log2 = log2;
exports.menuDelete = menuDelete;
exports.menuInfo = menuInfo;
exports.menuSaveUpdate = menuSaveUpdate;
exports.menuSelect = menuSelect;
exports.navListsx = navListsx;
exports.releaseCreate = releaseCreate;
exports.releaseList = releaseList;
exports.roleDel = roleDel;
exports.roleInfo = roleInfo;
exports.roleList = roleList;
exports.roleSaveUpdates = roleSaveUpdates;
exports.roleSelect = roleSelect;
exports.submitfile = submitfile;
exports.sysOrg_delete = sysOrg_delete;
exports.sysOrg_info = sysOrg_info;
exports.sysOrg_queryAll = sysOrg_queryAll;
exports.sysOrg_save_update = sysOrg_save_update;
exports.userDel = userDel;
exports.userInfo = userInfo;
exports.userList = userList;
exports.userList2 = userList2;
exports.userPassword = userPassword;
exports.userResetPw = userResetPw;
exports.userSaveUpdate = userSaveUpdate;
var _bossRequest = _interopRequireDefault(require("@/utils/bossRequest"));
var _tempRequest = _interopRequireDefault(require("@/utils/tempRequest"));
var _wholesaleRequest = _interopRequireDefault(require("@/utils/wholesaleRequest"));
/**
 *侧边栏菜单
 *
 */
function navListsx() {
  return (0, _bossRequest.default)({
    url: 'sysMenu/list',
    method: 'get'
  });
}
function menuSelect() {
  return (0, _bossRequest.default)({
    url: 'sysMenu/select',
    method: 'get'
  });
}
function menuInfo(data) {
  return (0, _bossRequest.default)({
    url: 'sysMenu/info/' + data,
    method: 'get'
  });
}
function menuSaveUpdate(data, type) {
  return (0, _bossRequest.default)({
    url: 'sysMenu/' + type,
    method: 'post',
    data: data
  });
}
function menuDelete(data) {
  return (0, _bossRequest.default)({
    url: 'sysMenu/delete/' + data,
    method: 'post'
  });
}

/**
 * 角色权限
 */
function roleList(data) {
  return (0, _bossRequest.default)({
    url: 'sysRole/list?page=' + data.page + '&limit=' + data.limit + '&roleName=' + data.roleName,
    method: 'get'
  });
}
function roleInfo(data) {
  return (0, _bossRequest.default)({
    url: 'sysRole/info/' + data,
    method: 'get'
  });
}
function roleSelect() {
  return (0, _bossRequest.default)({
    url: 'sysRole/select',
    method: 'get'
  });
}
function roleSaveUpdates(data, type) {
  return (0, _bossRequest.default)({
    url: 'sysRole/' + type,
    method: 'post',
    data: data
  });
}
function roleDel(data) {
  return (0, _bossRequest.default)({
    url: 'sysRole/delete',
    method: 'post',
    data: data
  });
}

/**
 * 管理员权限
 */
function userList(data) {
  return (0, _bossRequest.default)({
    url: 'sysUser/list?page=' + data.page + '&limit=' + data.limit + '&userName=' + data.userName + '&status=' + data.status,
    method: 'get'
  });
}
function userList2(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrderEvent/dealList?page=' + data.page + '&limit=' + data.limit + '&userName=' + data.userName,
    method: 'get'
  });
}
function userInfo(data) {
  return (0, _bossRequest.default)({
    url: 'sysUser/info/' + data,
    method: 'get'
  });
}
function userSaveUpdate(data, type) {
  return (0, _bossRequest.default)({
    url: 'sysUser/' + type,
    method: 'post',
    data: data
  });
}
function userDel(data) {
  return (0, _bossRequest.default)({
    url: 'sysUser/delete',
    method: 'post',
    data: data
  });
}
function userPassword(data) {
  return (0, _bossRequest.default)({
    url: 'sysUser/password',
    method: 'post',
    data: data
  });
}
function userResetPw(data) {
  return (0, _bossRequest.default)({
    url: 'sysUser/resetPw',
    method: 'post',
    data: data
  });
}
/**
 * 组织权限
 * orgName，orgType，parentName
 */
// 组织列表
function sysOrg_queryAll(data) {
  return (0, _bossRequest.default)({
    url: 'sysOrg/queryAll?orgName=' + data.orgName + '&orgType=' + data.orgType + '&parentName=' + data.parentName,
    method: 'get'
  });
}
// 组织机构详情
function sysOrg_info(data) {
  return (0, _bossRequest.default)({
    url: 'sysOrg/info/' + data,
    method: 'get'
  });
}

// 删除机构
function sysOrg_delete(data) {
  return (0, _bossRequest.default)({
    url: 'sysOrg/delete',
    method: 'post',
    data: data
  });
}

// 修改、保存机构
function sysOrg_save_update(data, type) {
  return (0, _bossRequest.default)({
    url: !type ? 'sysOrg/save' : 'sysOrg/update',
    method: 'post',
    data: data
  });
}

// 日志 废弃
function log(data) {
  return (0, _bossRequest.default)({
    url: '/sysLog/list?page=' + data.page + '&limit=' + data.limit + '&action=' + data.action + '&userName=' + data.userName + '&startTime=' + data.startTime + '&endTime=' + data.endTime,
    method: 'get'
  });
}
// 日志
function log2(data) {
  return (0, _wholesaleRequest.default)({
    url: 'admin/adminSyslog/search',
    method: 'POST',
    data: data
  });
}

// 前店商品上传
function submitfile(data) {
  return (0, _tempRequest.default)({
    url: 'frontendStoreQuantity/importFrontendProducts',
    method: 'POST',
    data: data
  });
}

// 发布列表
function releaseList(data) {
  return (0, _bossRequest.default)({
    url: 'sysProject/list',
    method: 'POST',
    // eslint-disable-next-line no-undef
    data: data
  });
}

// 发布
function releaseCreate(data) {
  return (0, _bossRequest.default)({
    url: 'sysProject/add',
    method: 'POST',
    data: data
  });
}