"use strict";

var _interopRequireDefault = require("/home/devops/repos/1_20241012111234/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getPageTitle;
var _lang = _interopRequireDefault(require("@/lang"));
// import defaultSettings from '@/settings'

var title = '兽医首选 · Vets 云仓业务运营支撑系统';
function getPageTitle(key) {
  var hasKey = _lang.default.te("route.".concat(key));
  if (hasKey) {
    var pageName = _lang.default.t("route.".concat(key));
    return "".concat(pageName, " - ").concat(title);
  }
  return "".concat(title);
}