"use strict";

var _interopRequireDefault = require("/home/devops/repos/1_20240913155846/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.releaseMonth = releaseMonth;
var _bossRequest = _interopRequireDefault(require("@/utils/bossRequest"));
function releaseMonth() {
  return (0, _bossRequest.default)({
    url: '/sysProject/yeaMonthList',
    method: 'get'
  });
}